import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationComponent } from './authentication.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AddCardModalComponent } from './addCard-modal/addCard-modal.component';
import { ConfirmEmaiModalComponent } from './confirm-email-modal/confirm-email-modal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { EmailMaskPipe } from 'src/app/shared/pipes/email-mask.pipe';
import {
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [
    AuthenticationComponent,
    AddCardModalComponent,
    ConfirmEmaiModalComponent,
    EmailMaskPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    MatDialogModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  exports: [
    AuthenticationComponent,
    AddCardModalComponent,
    ConfirmEmaiModalComponent,
  ],
})
export class AuthenticationModule {}
