import { ToastrService } from 'ngx-toastr';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../../payment/service/payment.service';
import { map, catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { errorExtractor } from 'src/app/utils/utils';
import { captureException } from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class GiftcardService {
  allGiftcards = [];
  allMemberships = [];
  progress: boolean = true;
  membershipProgress: boolean = true;
  rewardPoints;
  userCardDetails;
  gift_cards = [];
  giftCardCategories = [];
  apiUrl = environment.apiUrlBsV1;
  offerPathV2 = environment.offerPathV2;
  burnRatioData;

  private itemsInCart: BehaviorSubject<any[]> = new BehaviorSubject([]);
  cartItems: EventEmitter<any> = new EventEmitter<[]>();
  closeModalEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private paymentService: PaymentService,
    private toastr: ToastrService
  ) {
    // this.getAllGiftCards();
  }

  getCategories(benefit_type: string, isDiscountedGc = false): Promise<any> {
    let httpOptions = {}
    if (isDiscountedGc) {
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
  
    return this.http
      .get(this.offerPathV2 + `/categories?benefit_type=${benefit_type}`, httpOptions)
      .toPromise();
  }

  getMemberships(pageNo, itemsPerPage) {
    return this.http
      .get(
        this.offerPathV2 +
          '/memberships' +
          ('?page=' + pageNo) +
          ('&per_page=' + itemsPerPage)
      )
      .toPromise();
  }

  getMembershipDetails(id) {
    return this.http.get(this.offerPathV2 + `/memberships/${id}`).toPromise();
  }

  getAllMemberships() {
    return new Promise<void>((resolve) => {
      this.getMemberships(1, 500).then((res: any) => {
        this.allMemberships = res.data;
        this.membershipProgress = false;
        resolve();
      });
    });
  }

  public getGiftcards(pageNo, itemsPerPage, popular?: boolean, isDiscountedGc = false): Promise<any> {
    let httpOptions = {}
    if(isDiscountedGc){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }

    return this.http
      .get(this.offerPathV2 + '/gift_cards' + ('?page=' + pageNo) + ('&per_page=' + itemsPerPage) + (popular ? ('&popular=' + popular) : ''), httpOptions)
      .toPromise();
  }

  public getGiftcardDetails(id: string, isDiscountedGc = false) {
    let httpOptions = {}
    if(isDiscountedGc){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    return this.http.get(this.offerPathV2 + `/gift_cards/${id}`, httpOptions).toPromise();
  }

  public getAllGiftCards(isDiscountedGc = false): Promise<any> {
    return new Promise<void>((resolve) => {
      this.getGiftcards(1, 500, false, isDiscountedGc).then((res: any) => {
        this.allGiftcards = res.data;
        this.progress = false;
        resolve();
      });
    });
  }
  getGiftcardsItems() {
    let ids = [
      '1a51953e-6439-4806-98c6-0aebeb0a617b',
      '19b069c0-cdd6-4979-9a4c-d9c1602ffe5b',
    ];
    return this.http
      .get<any>(
        this.offerPathV2 + '/gift_cards' + '?page=1' + ('&per_page=' + 500),
        {}
      )
      .pipe(
        map((res) => res || res),
        catchError((error) => throwError(error))
      );
  }
  deleteFromCart(deleteData, bookingId) {
    return this.http
      .post(this.apiUrl + `carts/remove_item`, deleteData)
      .toPromise();
  }

  // TODO: add type for return type
  public clearCartItems(isDiscountedGc = false): Promise<any> {
    const data = {
      category: 'GiftCard',
    };

    let httpOptions = {};

    if(isDiscountedGc){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }

    return this.http.post(this.apiUrl + `carts/clear_cart`, data, httpOptions).toPromise();
  }

  public pushToCart(cartParams, isDiscountedGc = false) {
    let httpOptions = {}
    if(isDiscountedGc){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    return this.http
      .post(
        this.apiUrl + `carts/add_items`,
        cartParams,
        httpOptions
      );
  }

  public updateCartDetails(data): void {
    this.itemsInCart.next(data);
  }

  public getGiftcardsInCart(isDiscountedGc = false) {
    let httpOptions = {}
    if(isDiscountedGc){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    return this.http
      .get(
        this.apiUrl + `carts/list_items?category=GiftCard`, httpOptions
      ).pipe(tap((res: any) => {
        // this.itemsInCart.next(res.data);
        this.updateCartDetails(res.data)
      }),
      catchError((error: HttpErrorResponse) => {
        const extractedError =
        errorExtractor(error) || "Handled unknown error";
        const myError = new Error(extractedError);
        myError.name = "Retrieval of cart items failed";
        captureException(myError, {
          tags: {
            rank: "p1",
            message: myError.name,
            isBackendException: true,
          },
        });
        return of(error);
      })
      ).toPromise();
  }

  public checkout(isDiscountedGc = false) {
    let httpOptions = {}
    if(isDiscountedGc){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    const data = {
      "category": "GiftCard"
    }
    return this.http
      .post(
        this.apiUrl + `carts/checkout`, data, httpOptions
      );
  }

  createMembershipBooking(data) {
    return this.http.post(this.apiUrl + 'memberships/create_booking', data);
  }

  updateMembershipBooking(data) {
    return this.http
      .post(this.apiUrl + 'memberships/update_booking', data)
      .toPromise();
  }

  addUserData(data) {
    return this.http
      .post(this.apiUrl + `gift_cards/add_user_data`, data)
      .toPromise();
  }

  getBookingById(id) {
    return this.http.get(this.apiUrl + `bookings/${id}`).toPromise();
  }

  searchGiftcards(searchquery) {
    return this.http.get(
      this.offerPathV2 + '/gift_cards' + ('?search=' + searchquery)
    );
  }

  getBurnRatio(burnRatioId = '', otherParams = {}) {
    return new Promise<void>(
      (resolve, reject) => {
        // if (this.burnRatioData) { resolve(this.burnRatioData); }
        // else {
        this.fetchBurnRatio(burnRatioId, otherParams).then((res) => {
          this.burnRatioData = res;
          resolve(this.burnRatioData);
        });
      }
      // }
    );
  }

  fetchBurnRatio(burnRatioId, otherParams) {
    return this.paymentService.getBurnRatio(burnRatioId, otherParams);
  }

  getAllBookings() {
    return this.http
      .get(environment.apiUrlBsV1 + `/bookings?type=GiftCardBooking`)
      .toPromise();
  }

  resendGiftcards(bookingId) {
    return this.http
      .get(
        environment.apiUrlBsV1 +
          '/gift_cards/resend_gift_card?gift_card_order_id=' +
          bookingId
      )
      .toPromise();
  }

  cancelBookingRequest(bookingUniqueId, reason) {
    return this.http
      .post(environment.stagingUrl + 'users/bookings/request_cancellation', {
        booking_unique_id: bookingUniqueId,
        reason: reason,
      })
      .toPromise();
  }

  getBookingsById(id) {
    return this.http
      .get(environment.apiUrlBsV1 + `/bookings/${id}`)
      .toPromise();
  }
}
