import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AESEncryptDecryptService {
  constructor() {}

  encrypt(value: string): string {
    // return CryptoJS.AES.encrypt(value, environment.secretKey).toString();
    return value;
  }

  decrypt(textToDecrypt: string) {
    // return CryptoJS.AES.decrypt(textToDecrypt, environment.secretKey).toString(CryptoJS.enc.Utf8);
    return textToDecrypt;
  }
}
