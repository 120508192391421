<div class="relative">
  <div class="close-box pointer logo absolute rounded-circle d-flex">
    <svg
      class="pointer modal-close-svg m-auto"
      width="13"
      height="13"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1.51071L13.4893 0L7.5 5.98929L1.51071 0L0 1.51071L5.98929 7.5L0 13.4893L1.51071 15L7.5 9.01071L13.4893 15L15 13.4893L9.01071 7.5L15 1.51071Z"
        fill="#919aa2"
      />
    </svg>
  </div>
  <div class="d-flex flex-column">
    <img
      src="../../../../assets/announcement.webp"
      alt=""
      class="announcement-img mar-bot-24"
    />
    <div>
      DBS Bank is always striving to bring you the best of experiences. In this
      context our reward redemption platform will be
      <b>undergoing</b>
      a
      <b>maintenance</b>
      between
      <b>00:00hrs</b>
      and
      <b>03:00hrs</b>
      IST, and redemption options will be unavailable during this time.
    </div>
    <div class="mt-2">We sincerely regret any inconvenience caused.</div>
  </div>
</div>
