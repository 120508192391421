import { PointsXChangeConfig } from '@pv-frontend/points-xchange';
import { pointsXChangeConfig } from './points-xchange';
import { ChipStyleOverride } from '@pv-frontend/pv-shared-components/chip';

const categoryConfig: ChipStyleOverride = {
  container: {
    borderRadius: '42px',
    padding: '8px 21px',
  },
  label: {
    fontSize: '16px',
  },
  crossIcon: {
    width: '20px',
    height: '20px',
  },
};

export const pointsXChangeDesktopConfig: PointsXChangeConfig = {
  ...pointsXChangeConfig,
  landingPage: {
    listing: {
      style: {
        category: {
          ...pointsXChangeConfig?.landingPage?.listing?.style?.category,
          container: {
            ...pointsXChangeConfig?.landingPage?.listing?.style?.category
              ?.container,
            ...categoryConfig.container,
          },
          label: {
            ...pointsXChangeConfig?.landingPage?.listing.style?.category?.label,
            ...categoryConfig.label,
            fontSize: '16px',
          },
          crossIcon: {
            ...pointsXChangeConfig?.landingPage?.listing?.style?.category
              ?.crossIcon,
            ...categoryConfig.crossIcon,
            width: '20px',
            height: '20px',
          },
        },
        activeCategory: {
          ...pointsXChangeConfig?.landingPage?.listing?.style?.activeCategory,
          ...categoryConfig,
        },
        pointsProviderCard: {
          ...pointsXChangeConfig?.landingPage?.listing?.style
            ?.pointsProviderCard,
          cardContainer: {
            ...pointsXChangeConfig?.landingPage?.listing?.style
              ?.pointsProviderCard?.cardContainer,
            borderRadius: '12px',
          },
          cardContent: {
            ...pointsXChangeConfig?.landingPage?.listing?.style
              ?.pointsProviderCard?.cardContent,
            padding: '24px 16px',
          },
          image: {
            ...pointsXChangeConfig?.landingPage?.listing?.style
              ?.pointsProviderCard?.image,
            borderRadius: '12px 12px 0px 0px',
          },
          information: {
            ...pointsXChangeConfig?.landingPage?.listing?.style
              ?.pointsProviderCard?.information,
            attentionText: {
              ...pointsXChangeConfig?.landingPage?.listing?.style
                ?.pointsProviderCard?.information.attentionText,
              fontSize: '14px',
            },
          },
          title: {
            ...pointsXChangeConfig?.landingPage?.listing?.style
              ?.pointsProviderCard?.title,
            fontSize: '16px',
          },
          subTitle: {
            ...pointsXChangeConfig?.landingPage?.listing?.style
              ?.pointsProviderCard?.subTitle,
            fontSize: '16px',
            'padding-top': '10px !important',
          },
          divider: {
            ...pointsXChangeConfig?.landingPage?.listing?.style
              ?.pointsProviderCard?.divider,
            margin: '20px 0px',
          },
        },
        cardBanner: {
          style: {
            tag: {
              height: '2.25rem',
            },
            label: {
              fontSize: '12px',
              padding: '0px 12px'
            },
          },
        }
      },
    },
  },
  transferHistoryPage: {
    ...pointsXChangeConfig?.transferHistoryPage,
    style: {
      ...pointsXChangeConfig?.transferHistoryPage?.style,
      listing: {
        ...pointsXChangeConfig?.transferHistoryPage?.style?.listing,
        container: {
          ...pointsXChangeConfig?.transferHistoryPage?.style?.listing?.container,
          width: '75%'
        }
      }
    }
  },
  transferHistoryDetailsPage: {
    ...pointsXChangeConfig?.transferHistoryDetailsPage,
  }
};
