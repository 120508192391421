import { Component, OnInit } from '@angular/core';
import { appConstants } from 'src/app/app.constants';
import { ThemeService } from '../../services/theming.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public currentYear: number = new Date().getFullYear();
  public isCurrentDomainTreasures: boolean =
    window.location.hostname.includes(appConstants.dbsTreasuresUrlKeyword);

  constructor(private themeService: ThemeService) {}

  public ngOnInit(): void {
    if (this.isCurrentDomainTreasures) {
      this.themeService.setThemes('treasures');
    }
  }
}
