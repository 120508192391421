import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  apiUrl = environment.apiUrlPaV1;
  v2ApiBs = environment.apiUrlBsV1;
  private pointData = new BehaviorSubject(0);
  pointData$ = this.pointData.asObservable();
  constructor(private http: HttpClient) {}

  changePointsData(data: any) {
    this.pointData.next(data);
  }

  createPayment(data) {
    return this.http.post(this.apiUrl + 'payments', data).toPromise();
  }

  getCardDetails(number) {
    // return this.http.get(this.apiUrl + 'payments/get_card_details?card_num=' + number);
    const param = {
      card_num: number,
    };
    return this.http.post(this.apiUrl + 'payments/get_card_details', param);
  }

  getPaymentDetails(bookingId) {
    return this.http.get(this.apiUrl + 'payments/' + bookingId).toPromise();
  }

  saveUserCard(data) {
    return this.http
      .post(this.apiUrl + 'payments/save_user_card', data)
      .toPromise();
  }

  getUserCards() {
    return this.http
      .post(this.apiUrl + 'payments/get_user_cards', {})
      .toPromise();
  }

  getAllPromoCode(service_name?: string) {
    return (
      this.http
        // .get(this.v2ApiBs + `/promo_codes`);
        .get(
          this.v2ApiBs +
            `/promo_codes` +
            (service_name ? '?service_name=' + service_name : '')
        )
    );
  }

  getPromoCodeDiscount(bookingId, promoCode) {
    let body = {
      booking_id: bookingId,
      code: promoCode,
    };
    return this.http.post(this.v2ApiBs + `/promo_codes/apply`, body);
  }

  removeCouponCode(bookingId, promoCode) {
    let body = {
      booking_id: bookingId,
      code: promoCode,
    };
    return this.http.post(this.v2ApiBs + `/promo_codes/remove`, body);
  }
}
