import { InjectionToken, Injector } from '@angular/core';
import * as _moment from 'moment';
const moment = _moment;

export const LOCALE_CONFIG = new InjectionToken<LocaleConfig>(
  'daterangepicker.config'
);
/**
 *  LocaleConfig Interface
 */
export interface LocaleConfig {
  direction?: string;
  separator?: string;
  weekLabel?: string;
  applyLabel?: string;
  cancelLabel?: string;
  clearLabel?: string;
  customRangeLabel?: string;
  daysOfWeek?: string[];
  monthNames?: string[];
  firstDay?: number;
  format?: string;
}
/**
 *  DefaultLocaleConfig
 */
export const DefaultLocaleConfig: LocaleConfig = {
  direction: 'ltr',
  separator: ' → ',
  weekLabel: 'W',
  format: 'DD MMM, YYYY',
  applyLabel: 'Apply',
  cancelLabel: 'Cancel',
  clearLabel: 'Clear',
  customRangeLabel: 'Custom range',
  daysOfWeek: 'S_M_T_W_T_F_S'.split('_'),
  monthNames: moment.months(),
  firstDay: 0, //.firstDayOfWeek()
};
