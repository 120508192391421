<div class="container mt-5 pt-5 mb-5 pb-5">
  <div class="row justify-content-center align-items-center">
    <div
      class="col-12 text-fail align-content-center justify-content-center d-flex"
    >
      <div class="text-center">
        <img src="assets/error.svg" class="img-fluid my-4 error-image" />
        <h4 class="text-fail">Oops! Booking Failed</h4>
        <p class="text-muted my-3">
          The Booking couldn’t be made. Please retry.
        </p>
        <hr />
        <div class="text-left">
          <p class="text-muted my-2">
            For support related queries, Kindly reach out to
          </p>
          <div class="row mt-3 mb-1">
            <div class="col-5 pad-right-0">
              <p class="fnt-12">
                <span class="icon-call contact-icon mr-1"></span>
                <a
                  [href]="'tel:+' + GlobalConstants.data?.phone?.val"
                  class="c333"
                >
                  {{ GlobalConstants.data.phone?.display }}
                </a>
              </p>
            </div>
            <div class="col-7 text-right pad-left-5">
              <p class="fnt-12">
                <span class="icon-mail contact-icon mr-1"></span>
                <a
                  [href]="'mailto:' + GlobalConstants.data.email.val"
                  class="c333"
                >
                  {{ GlobalConstants.data.email.display }}
                </a>
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div class="text-left mt-3">
          <a href="/" class="btn-link fnt-16 flex align-items-center">
            <svg
              width="5"
              height="8"
              viewBox="0 0 5 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mar-right-10 mar-bot-3"
            >
              <path
                d="M4 1L1 4L4 7"
                stroke="#9d1d27"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Retry
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
