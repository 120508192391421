<div class="searchModal">
  <div class="dropdown relative">
    <section
      class="relative secrch-inp-p"
      [ngClass]="{
        'open-borders-head': searchInput.length > 0,
        'normal-borders-head': searchInput.length === 0
      }"
    >
      <input
        placeholder="I am looking for..."
        class="search-inp w100 fnt-14 pad-top-2"
        [(ngModel)]="searchInput"
        id="search-input"
        (input)="debouncedMerchantSearch($event)"
        autocomplete="off"
      />
      <svg
        class="search-ic absolute"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.15374 14.3042C8.90454 14.3037 10.5937 13.657 11.8981 12.4878L16.2823 16.8772C16.3612 16.9559 16.4682 17.0001 16.5796 17C16.6911 16.9999 16.798 16.9555 16.8768 16.8766C16.9556 16.7977 16.9999 16.6907 17 16.5791C17.0001 16.4675 16.956 16.3605 16.8773 16.2814L12.4932 11.892C13.5764 10.6666 14.2071 9.10632 14.2801 7.47154C14.3531 5.83676 13.8639 4.22635 12.8943 2.90902C11.9246 1.5917 10.5331 0.647128 8.95182 0.232917C7.37058 -0.181295 5.69528 -0.0400932 4.20546 0.632961C2.71565 1.30601 1.50141 2.47021 0.765362 3.93131C0.0293114 5.3924 -0.184037 7.06201 0.160914 8.66157C0.505866 10.2611 1.38825 11.6939 2.66085 12.7208C3.93344 13.7476 5.51927 14.3066 7.15374 14.3042ZM7.15374 0.82239C8.40215 0.82239 9.62253 1.19303 10.6606 1.88744C11.6986 2.58185 12.5076 3.56883 12.9854 4.72359C13.4631 5.87835 13.5881 7.14902 13.3445 8.3749C13.101 9.60079 12.4998 10.7268 11.6171 11.6106C10.7343 12.4945 9.60959 13.0963 8.38517 13.3402C7.16074 13.584 5.89159 13.4589 4.7382 12.9806C3.58482 12.5023 2.59901 11.6923 1.90542 10.653C1.21184 9.61374 0.841644 8.39191 0.841644 7.14201C0.843527 5.46652 1.50915 3.86019 2.69249 2.67544C3.87583 1.4907 5.48024 0.824275 7.15374 0.82239Z"
          fill="#0F3464"
        />
      </svg>
      <svg
        class="close-ic absolute pointer"
        (click)="hideModal()"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 1.51071L13.4893 0L7.5 5.98929L1.51071 0L0 1.51071L5.98929 7.5L0 13.4893L1.51071 15L7.5 9.01071L13.4893 15L15 13.4893L9.01071 7.5L15 1.51071Z"
          fill="#333333"
        />
      </svg>
    </section>
    <div
      class="dropdown-menu w100 pad-7 se-drop s-dropdown"
      [ngClass]="{ 'open-borders-panel': searchInput.length > 0 }"
      *ngIf="searchInput.length > 0"
    >
      <div class="block loc-results">
        <div class="option-box pointer" *ngFor="let item of itemList">
          <span
            (click)="onItemClick(item)"
            class="option-name c555 f500 flex w100"
          >
            {{ item.name || item.attributes?.name || item.attributes?.title }}
          </span>
          <span class="type-label">
            {{ labelMapper[item.type] }}
          </span>
        </div>
        <div
          class="option-box"
          *ngIf="
            !searching &&
            itemList &&
            itemList.length == 0 &&
            searchInput.length > 2
          "
        >
          <div class="no-mer-found">No Such Merchant Found</div>
        </div>
        <div class="option-box" *ngIf="searching">
          <div class="no-mer-found">Loading...</div>
        </div>
        <div
          class="option-box"
          *ngIf="
            searchInput &&
            searchInput.length < 3 &&
            (!itemList || itemList.length === 0)
          "
        >
          <div class="no-mer-found">Type at least 3 characters</div>
        </div>
      </div>
    </div>
  </div>
</div>
