import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss'],
})
export class Error404Component implements OnInit {
  autoRedirectTime: number = 10;

  constructor(private pageTitle: Title) {}

  ngOnInit() {
    this.pageTitle.setTitle('404 Not found');
    window.scrollTo(0, 0);
    let internalSub = setInterval(() => {
      if (this.autoRedirectTime >= 1) {
        this.autoRedirectTime -= 1;
      } else {
        window.location.href = '/';
      }
    }, 1000);
  }
}
