// utils/error-helper.ts file

import { HttpErrorResponse } from "@angular/common/http";

const isErrorOrErrorLikeObject = (value) => {
    if (value instanceof Error) {
      return true;
    }
    if (value === null || typeof value !== "object") {
      return false;
    }
  
    if(value?.error) {
      return value.error
    }
  
    return value?.name || value?.message;
  };

const extractHttpModuleError = (error) => {
  // The `error` property of http exception can be either an `Error` object, which we can use directly...
  if (error?.error) {
    // Poshvine sends error in a particular format, hence we are extracting error message
    if (Array.isArray(error.error?.errors)) {
      return error.error.errors.join(", ");
    }
    return error.error;
  }
  // ... or an`ErrorEvent`, which can provide us with the message but no stack...
  if (error.error instanceof ErrorEvent && error.error.message) {
    return error.error.message;
  }
  // ...or the request body itself, which we can use as a message instead.
  if (typeof error.error === "string") {
    return `Server returned code ${error.status} with body "${error.error}"`;
  }
  // If we don't have any detailed information, fallback to the request message itself.
  return error.message;
};

export const errorExtractor = (error) => {
  // If it's http module error, extract as much information from it as we can.
  if (error instanceof HttpErrorResponse) {
    return extractHttpModuleError(error);
  }
  // We can handle messages and Error objects directly.
  if (typeof error === "string" || isErrorOrErrorLikeObject(error)) {
    return error;
  }
  // Nothing was extracted, fallback to default error message.
  return null;
};