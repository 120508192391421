import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { TokenInterceptor } from 'src/app/authentication/token.interceptor';
import { ErrorInterceptor } from 'src/app/authentication/error.interceptor';
import { GlobalConstants } from 'src/app/pages/common/global-variables';
import { SpecialProjectCheck } from 'src/app/shared/services/common/special-project-check';
import { DataTransferService } from 'src/app/shared/services/common/datatransfer.service';
import { ApiService } from 'src/app/pages/common/api.service';
import { TitleCasePipe } from '@angular/common';
import { BookingFailedComponent } from './pages/booking-failed/booking-failed.component';
import { IfSureComponent } from './pages/common/if-sure/if-sure.component';
import { PaymentModule } from './pages/payment/payment.module';
import { PvPointsXchangeModule } from '@pv-frontend/points-xchange';
import { JOURNEY_METADATA_SERVICE_TOKEN } from '@pv-frontend/pv-shared-services/journey-metadata-service';
import { PV_USER_SERVICE } from '@pv-frontend/pv-shared-services/pv-user-service';
import { JourneyMetaDataServiceImpl } from './services/journey-metadata-impl.service';
import { PvUserServiceImpl } from './services/user-service-impl.service';
import { MessageService } from 'primeng/api';
import {MatExpansionModule} from '@angular/material/expansion';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular-ivy";

declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
  }
}
@NgModule({
  declarations: [
    AppComponent,
    Error404Component,
    Error500Component,
    BookingFailedComponent,
    IfSureComponent,
    // CampaignBookingHistoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    PaymentModule.forRoot(environment),
    PvPointsXchangeModule.forRoot(environment),
    MatExpansionModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    GlobalConstants,
    DataTransferService,
    ApiService,
    SpecialProjectCheck,
    TitleCasePipe,
    JourneyMetaDataServiceImpl,
    PvUserServiceImpl,
    {
      provide: JOURNEY_METADATA_SERVICE_TOKEN,
      useExisting: JourneyMetaDataServiceImpl,
    },
    {
      provide: PV_USER_SERVICE,
      useExisting: PvUserServiceImpl,
    },
    {
      provide: 'environment',
      useValue: environment,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    MessageService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
