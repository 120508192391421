import { Time24to12Pipe } from './pipes/time24to12.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ImagePreloadDirective } from './directives/image-preload.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NoDataComponent } from './components/no-data/no-data.component';
import { FooterComponent } from './components/footer/footer.component';
import { CouponModalComponent } from './components/coupon-modal/coupon-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxDaterangepickerMd } from './components/daterangepicker';

import { OffersCardComponent } from './components/offers-card/offers-card.component';
import { TermsModalComponent } from './components/terms-modal/terms-modal.component';
import { GiftCardComponent } from './components/gift-card/gift-card.component';
import { RouterModule } from '@angular/router';
import { GiftMemberMobComponent } from './components/gift-member-mob/gift-member-mob.component';
import { NewMembershipCardComponent } from './components/new-membership-card/new-membership-card.component';
import { LazyImgDirective } from './directives/lazyload.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { AuthenticationModule } from '../authentication/authentication.module';
import { HeaderComponent } from './components/header/header.component';
import { SearchComponent } from './components/header/search-modal/search-modal.component';
import { SearchBasicComponent } from './components/header/search-basic/search-basic.component';
import { CardSwitchComponent } from './components/card-switch/card-switch.component';
import { CountryModalComponent } from './components/country-modal/country-modal.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TabsCartComponent } from './components/tabs-cart/tabs-cart.component';
import { CardLoaderComponent } from './components/loader/card-loader/card-loader.component';
import { NotEligibleModalComponent } from './components/not-eligible-modal/not-eligible-modal.component';
import { IndianCurrency } from './pipes/indian-currency.pipe';
import { CreditCardMaskPipe } from './pipes/credit-card-mask.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { CleanHtmlPipe } from './pipes/clean-html.pipe';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {
  MatLegacyDialogModule as MatDialogModule,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { IncrementDecrementPipe } from './pipes/increment-decrement.pipe';
import { BusTimingPipe } from './pipes/bus-timing.pipe';
import { RemoveUnderscorePipe } from 'src/app/shared/pipes/remove-underscore.pipe';
import { PromoTncComponent } from 'src/app/shared/components/promo-tnc/promo-tnc.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { CouponCodeComponent } from 'src/app/shared/components/coupon-code/coupon-code.component';
import { StatusLabelComponent } from 'src/app/shared/components/status-label/status-label.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterExtService } from 'src/app/shared/services/router-ext.service';
import { ClipboardModule } from 'ngx-clipboard';
import { ReplacePipe } from './pipes/replace.pipe';
import {
  NgbDatepickerModule,
  NgbModule,
  NgbDate,
} from '@ng-bootstrap/ng-bootstrap';
import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe';
import { CartComponent } from 'src/app/pages/gift-cards/cart/cart.component';
import { NotEligibleComponent } from './components/not-eligible/not-eligible.component';
import { CampaignTermsAndConditionModalComponent } from './campaign-terms-and-condition-modal/campaign-terms-and-condition-modal.component';
import { MaintenanceModalComponent } from './components/maintenance-modal/maintenance-modal.component';
import { VantageMembershipCardComponent } from './components/vantage-membership-card/vantage-membership-card.component';
// import { VantageMembershipCardComponent } from './components/vantage-membership-card/vantage-membership-card.component';
import { AutoFocus } from './directives/autofocus.directive';
import { BottomDrawerComponent } from './components/bottom-drawer/bottom-drawer.component';
import { CampaignMembershipRedemptionComponent } from './components/campaign-membership-redemption/campaign-membership-redemption.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [
    // Components
    NoDataComponent,
    FooterComponent,
    CouponModalComponent,
    OffersCardComponent,
    HeaderComponent,
    SearchComponent,
    SearchBasicComponent,
    CardSwitchComponent,
    CountryModalComponent,
    LoaderComponent,
    CardLoaderComponent,
    NotEligibleModalComponent,
    TabsCartComponent,
    GiftCardComponent,
    GiftMemberMobComponent,
    NewMembershipCardComponent,
    VantageMembershipCardComponent,
    PromoTncComponent,
    CouponCodeComponent,
    StatusLabelComponent,
    BottomDrawerComponent,
    CartComponent,
    // Directives
    ImagePreloadDirective,
    LazyImgDirective,
    TermsModalComponent,
    NumbersOnlyDirective,

    // Pipes
    IndianCurrency,
    CreditCardMaskPipe,
    FilterPipe,
    CleanHtmlPipe,
    IncrementDecrementPipe,
    RemoveUnderscorePipe,
    Time24to12Pipe,
    ReplacePipe,
    BusTimingPipe,
    MinuteSecondsPipe,
    NotEligibleComponent,
    CampaignTermsAndConditionModalComponent,
    MaintenanceModalComponent,
    AutoFocus,
    CampaignMembershipRedemptionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
      // disableTimeOut: true
    }),
    NgxDaterangepickerMd.forRoot({
      firstDay: 0,
    }),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule,
    AuthenticationModule,
    MatDialogModule,
    NgxSliderModule,
    MatRadioModule,
    NgxPaginationModule,
    ClipboardModule,
    NgbDatepickerModule,
    NgbModule,
  ],
  exports: [
    // Modules
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    ToastrModule,
    ModalModule,
    RouterModule,
    PopoverModule,
    NgxDaterangepickerMd,

    MatRadioModule,
    MatDialogModule,
    NgxPaginationModule,
    ClipboardModule,
    NgbDatepickerModule,
    NgbModule,
    // Components
    NoDataComponent,
    FooterComponent,
    CouponModalComponent,
    OffersCardComponent,
    HeaderComponent,
    SearchComponent,
    SearchBasicComponent,
    CardSwitchComponent,
    CountryModalComponent,
    LoaderComponent,
    CardLoaderComponent,
    NotEligibleModalComponent,
    TabsCartComponent,
    GiftCardComponent,
    GiftMemberMobComponent,
    NewMembershipCardComponent,
    VantageMembershipCardComponent,
    PromoTncComponent,
    CouponCodeComponent,
    StatusLabelComponent,
    BottomDrawerComponent,
    CartComponent,
    NotEligibleComponent,
    // Directives
    ImagePreloadDirective,
    LazyImgDirective,
    NumbersOnlyDirective,
    AutoFocus,
    // Pipes
    IndianCurrency,
    CreditCardMaskPipe,
    FilterPipe,
    CleanHtmlPipe,
    MatDialogModule,
    RemoveUnderscorePipe,
    IncrementDecrementPipe,
    Time24to12Pipe,
    ReplacePipe,
    BusTimingPipe,
    MinuteSecondsPipe,
    CampaignMembershipRedemptionComponent,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    RemoveUnderscorePipe,
    RouterExtService,
  ],
})
export class SharedModule {}
