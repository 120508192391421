import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/pages/common/global-variables';

@Component({
  selector: 'app-booking-failed',
  templateUrl: './booking-failed.component.html',
  styleUrls: ['./booking-failed.component.scss'],
  providers: [GlobalConstants],
})
export class BookingFailedComponent implements OnInit {
  constructor(public GlobalConstants: GlobalConstants) {}

  ngOnInit(): void {}
}
