<section class="step-three showLogin">
  <div class="close-box pointer logo mar-bot-20">
    <img
      [src]="
        GlobalConstants.data.logo[this.themeService.getActiveThemeName]
          ?.modal_url
      "
      class="img-fluid login-client-logo"
    />
  </div>
  <form [formGroup]="addCardForm" (submit)="addCardFormSubmit()">
    <div class="title">
      Enter
      <span>first 8 digits</span>

      <span>of your {{ GlobalConstants.data.titles.card_name }}</span>
    </div>

    <div class="card-block">
      <label>Card Number</label>
      <div class="flex main-flex">
        <div class="flex-3 pd-r7">
          <input
            type="text"
            #firstFocusable
            id="first_four_card_digits"
            class="form-control"
            (keyup)="onKeyph($event); keytab($event, focusable); checkbtn()"
            formControlName="binFirst4"
            autocomplete="off"
            minlength="4"
            maxlength="4"
            required
          />
        </div>
        <div class="flex-3 pd-l7">
          <input
            type="text"
            #focusable
            id="last_four_card_digits"
            class="form-control"
            formControlName="binLast4"
            autocomplete="off"
            minlength="4"
            maxlength="4"
            (keyup)="
              onKeyph($event); backkeytab($event, firstFocusable); checkbtn()
            "
            required
          />
        </div>
        <div class="flex-3 pd-r7">
          <div class="form-single-container">
            <input
              type="text"
              class="form-control text-center disabled"
              placeholder="XXXX"
              autocomplete="none"
              disabled
            />
          </div>
        </div>
        <div class="flex-3 pd-r7">
          <div class="form-single-container">
            <input
              type="text"
              class="form-control text-center disabled"
              (keyup)="onKeyph($event)"
              placeholder="XXXX"
              disabled
            />
          </div>
        </div>
      </div>
    </div>

    <div class="modal-foot mar-bot-50 pad-bot-20 clearfix">
      <div class="error-txt fnt-12 f500 pad-top-10" *ngIf="binError">
        <span>Please enter a valid Visa card number</span>
      </div>

      <button
        class="btn btn-theme"
        *ngIf="!btnActive"
        type="submit"
        disabled="disabled"
      >
        Continue
      </button>
      <button
        class="btn btn-theme relative"
        *ngIf="btnActive"
        type="submit"
        [ngStyle]="{ background: 'var(--primary-btn)' }"
      >
        Continue
        <div
          class="small-loader input-load merged-card-loader"
          *ngIf="isload2"
        ></div>
      </button>

      <div
        class="mar-top-30"
        *ngIf="GlobalConstants.data.personalized_config.has_add_card_text"
      >
        <div class="fnt-13" [ngStyle]="{ color: 'var(--primary-btn)' }">
          {{ GlobalConstants.data.content.add_card_text }}
        </div>
      </div>
    </div>
    <div class="flex last-b">
      <div class="ll">
        Your Card/ Account parameters are used only to check eligibility and are
        encrypted
      </div>
      <div class="rr">
        <img src="./assets/img/logos/PCI.svg" />
      </div>
    </div>
  </form>
</section>
