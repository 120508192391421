<footer
  class="footer-container sm:block lg:flex align-items-center justify-content-between w-full"
>
  <section class="lg:mr-6 xl:mr-8 mb-3 md:mb-6 lg:mb-0">
    <div class="flex">
      <img
        class="mb-4"
        [ngClass]="
          isCurrentDomainTreasures ? 'treasures-logo' : 'delights-logo'
        "
        [src]="
          isCurrentDomainTreasures
            ? 'https://images.poshvine.com/dbs/dbs_treasures.webp'
            : './assets/DBS/DBS_icon.png'
        "
      />
      <small class="fnt-12 currentYear ms-2">© {{ currentYear }}</small>
    </div>

    <p class="m-0 text-xs md:text-sm hidden md:block">
      Disclaimer: DBS Bank Bank Offers & Privileges is a platform for
      communication of personalized offers extended by Merchants to DBS Bank
      Bank's Customers. DBS Bank Bank is only communicating the offers extended
      by Merchants to its Customers and not Selling/Rendering any of these
      Products/Services. DBS Bank Bank is neither guaranteeing nor making any
      representation. DBS Bank Bank is not responsible for Sale/Quality/Features
      of the Products/Services under the offers.
    </p>
  </section>
  <section class="flex flex-column lg:align-items-center">
    <div
      class="flex align-items-center justify-content-between lg:justify-content-evenly mb-5 md:mb-5 lg:mb-4"
    >
      <a
        class="m-0 white-space-nowrap lg:px-2 info-link text-xxs md:text-sm pointer"
        href="/info/terms-and-conditions"
        target="_blank"
        rel="noreferrer noopener"
      >
        Terms & Conditions
      </a>
      <a
        class="m-0 white-space-nowrap lg:px-2 info-link text-xxs md:text-sm pointer"
        href="/info/privacy-policy"
        target="_blank"
        rel="noreferrer noopener"
      >
        Privacy Policy
      </a>
      <a
        class="m-0 white-space-nowrap lg:px-2 info-link text-xxs md:text-sm pointer"
        href="/info/customer-support"
        target="_blank"
        rel="noreferrer noopener"
      >
        Customer Support
      </a>
    </div>

    <section class="flex justify-content-between lg:block">
      <div class="mb-3">
        <div
          class="flex align-items-center contact-border p-1 lg:p-2 border-round-md mb-2"
        >
          <img
            class="p-1 lg:p-2 border-round-md contact-icon mr-1 md:mr-2"
            src="https://images.poshvine.com/dbs/mail.svg"
            alt="email"
          />
          <p class="m-0 text-xxs md:text-sm">
            {{
              isCurrentDomainTreasures
                ? 'support.dbs@poshvine.com'
                : 'support@poshvine.com'
            }}
          </p>
        </div>

        <div
          class="flex align-items-center contact-border p-1 lg:p-2 border-round-md"
        >
          <img
            class="p-1 lg:p-2 border-round-md contact-icon mr-1 md:mr-2"
            src="https://images.poshvine.com/dbs/phone.svg"
            alt="phone"
          />
          <p class="m-0 text-xxs md:text-sm">+91 1800-8437-889</p>
        </div>
      </div>

      <div
        class="flex flex-column md:flex-row align-items-center lg:w-8 md:px-2 lg:justify-content-between"
      >
        <section class="text-right mb-2 lg:mb-0">
          <p class="m-0 text-xxs md:text-sm">Powered by</p>
          <a href="https://razorpay.com/" target="_blank">
            <img
              class="rzp-logo"
              [src]="
                isCurrentDomainTreasures
                  ? 'https://images.poshvine.com/razorpay/razorpay-logo.png'
                  : 'https://images.poshvine.com/rzp_logo_dark.svg'
              "
              alt="razorpay logo"
            />
          </a>
        </section>
        <section>
          <img
            class="pci-logo md:ml-3"
            [src]="'./assets/img/other/pci.webp'"
            alt="pci-dss-logo"
          />
        </section>
      </div>
    </section>
  </section>
</footer>
