import { ThemeService } from './../../shared/services/theming.service';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/pages/common/global-variables';
import { ApiService } from 'src/app/pages/common/api.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-addCard-modal',
  templateUrl: './addCard-modal.component.html',
  styleUrls: ['./addCard-modal.component.scss'],
  providers: [ApiService, GlobalConstants],
})
export class AddCardModalComponent implements OnInit {
  addCardForm: any = this.fb.group({
    binFirst4: [''],
    binLast4: [''],
  });
  hideEvent: EventEmitter<boolean> = new EventEmitter();
  constructor(
    public GlobalConstants: GlobalConstants,
    private _apiService: ApiService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private _auth: AuthenticationService,
    public _bsModalRef: BsModalRef,
    protected themeService: ThemeService
  ) {}

  ngOnInit(): void {}

  hideModal() {
    this.hideEvent.emit(true);
  }
  binError: boolean;
  binInvalid: boolean;
  isload2: boolean;
  onKeyph(e) {
    this.binError = false;
    this.binInvalid = false;
    e.target.value = e.target.value.replace(/[^\d]/g, '').trim();
  }
  keytab(event, el) {
    this.binError = false;
    this.binInvalid = false;
    this.isload2 = false;
    if (event.currentTarget.value.length == 4) {
      // document.getElementById('last_four_card_digits').focus();
      el.focus();
    }
  }
  backkeytab(event, firstFocusable) {
    this.binError = false;
    this.binInvalid = false;
    this.isload2 = false;

    if (event.currentTarget.value.length == 0 && event.key == 'Backspace') {
      let elem = document.getElementById('first_four_card_digits');
      // elem.value = elem.value.slice(0, 3);
      elem.focus();
    }
  }
  btnActive: boolean = false;
  checkbtn() {
    this.btnActive =
      this.addCardForm.value.binFirst4.length +
        this.addCardForm.value.binLast4.length ==
      8
        ? true
        : false;
  }
  addCardFormSubmit() {
    this.isload2 = true;
    let bin =
      this.addCardForm.value.binFirst4.toString() +
      this.addCardForm.value.binLast4.toString();
    this._apiService
      .addCard(bin, this.GlobalConstants.data.card_num_type)
      .subscribe(
        (response) => {
          if (response && response.data && response.data.attributes) {
            this.isload2 = false;
            localStorage.removeItem('userCards');
            this.toastr.success('Card added successfully!');
            this._auth.reloadInit.emit(true);
            this._bsModalRef.hide();
          }
        },
        (err) => {
          this.isload2 = false;
          this.binError = true;
        }
      );
  }
}
