<section
  id="main-outer"
  [ngClass]="{ 'prevent-scroll': shouldScroll }"
>
  <app-header
    (hamburgerToggle)="checkScroll($event)"
    [ngClass]="{ 'header-fixed': isFlights }"
  ></app-header>
  <div class="router-container overflow-scroll" [ngClass]="{ 'mar-top-70': isFlights }">
    <router-outlet></router-outlet>
  </div>
</section>
<app-footer *ngIf="!hideFooter"></app-footer>

<app-loader *ngIf="isLoading"></app-loader>
