import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentComponent } from './payment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConciergePaymentComponent } from './concierge-payment/concierge-payment.component';
import { SliderComponent } from './slider/slider.component';

declare var Razorpay: any;

@NgModule({
  declarations: [PaymentComponent, ConciergePaymentComponent, SliderComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule],
  exports: [PaymentComponent, SliderComponent],
  providers: [],
})
export class PaymentModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: PaymentModule,
      providers: [
        {
          provide: 'env', // you can also use InjectionToken
          useValue: environment,
        },
        {
          provide: 'Razorpay',
          useValue: Razorpay,
        },
      ],
    };
  }
}
