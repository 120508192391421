import { Component, EventEmitter, OnInit } from '@angular/core';
import { ApiService } from 'src/app/pages/common/api.service';
import { debounceTime } from 'rxjs/operators';
import { Observable, Subject, forkJoin } from 'rxjs';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchComponent implements OnInit {
  private subject: Subject<string> = new Subject();

  // output vars
  searchItemEvent: EventEmitter<any> = new EventEmitter();
  hideEvent: EventEmitter<boolean> = new EventEmitter();
  selectedItemEvent: EventEmitter<any> = new EventEmitter();

  searching = false;
  itemList: Array<any>;
  labelMapper: any = {
    memberships: 'Membership',
    merchant: 'Offer',
    gift_card_list: 'Giftcard',
  };
  searchInput = '';

  constructor(public _apiService: ApiService) {}

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById('search-input').focus();
    }, 500);
    this.subject.pipe(debounceTime(500)).subscribe((inp) => {
      this.searchItem(inp);
    });
  }
  debouncedMerchantSearch(inp: any) {
    this.subject.next(inp);
  }
  hideModal() {
    this.hideEvent.emit(true);
  }

  searchItem(name) {
    if (this.searchInput.length > 2) {
      this.searching = true;
      this.itemList = [];
      const params = { name: this.searchInput };

      const apiObj = {
        offers: this._apiService.searchMerchants(params),
        giftcards: this._apiService.searchGiftcards(this.searchInput),
        memberships: this._apiService.searchMerchants({
          ...params,
          benefit_type: 'memberships',
        }),
      };
      forkJoin(apiObj).subscribe((resp: any) => {
        if (resp) {
          if (resp && resp['offers'] && resp['offers']?.data?.length) {
            this.itemList = resp['offers']?.data.slice(0, 7);
          }
          if (resp && resp['giftcards'] && resp['giftcards']?.data?.length) {
            this.itemList.push(...resp['giftcards']?.data.slice(0, 7));
          }
          if (
            resp &&
            resp['memberships'] &&
            resp['memberships']?.data?.length
          ) {
            resp['memberships']?.data.forEach((elem: any) => {
              if (elem) {
                elem['type'] = 'memberships';
              }
            });
            this.itemList.push(...resp['memberships']?.data.slice(0, 7));
          }
          this.searching = false;
        } else {
          (err) => {
            this.searching = false;
          };
        }
      });
      /* this._apiService.searchMerchants(params).subscribe(
        (res: any) => {
          if (res && res.data) {
            this.itemList = res.data.slice(0, 7);
          }
          this.searching = false;
        },
        (err) => {
          this.searching = false;
        }
      ); */
    } else {
      this.searching = false;
      this.itemList = [];
    }
  }

  onItemClick(item) {
    this.selectedItemEvent.emit({
      item,
    });
  }
}
