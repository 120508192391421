import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from './global-variables';
import {
  GiftCardDetailResponse,
  MembershipDetailResponse,
} from '../treasures/treasures.interface';
import {
  AirlinesReponse,
  Airport,
  BookingResponse,
  CampaignApiParams,
  CampaignEligibilityResponse,
  FormMetaDataResponse,
} from '../treasures-homepage/homepage.interface';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    public GlobalConstants: GlobalConstants
  ) {
    if (this.GlobalConstants.data.api_version == 'v2') {
      environment.corePath = environment.corePathV2;
      environment.offerPath = environment.offerPathV2;
    }
  }
  reloadTemplate = new EventEmitter<any>();

  get(endpoint, params?) {
    return this.http.get(endpoint, params);
  }

  post(endpoint, body, params?) {
    return this.http.post(endpoint, body, params);
  }

  delete(endpoint, params?) {
    return this.http.delete(endpoint, params);
  }

  getUser(userId) {
    return this.http
      .get<any>(environment.corePath + '/users/' + userId, {})
      .pipe(
        map((res) => res || res),
        catchError((error) => throwError(error))
      );
  }
  getUserDetails() {
    return this.http.get<any>(environment.corePath + '/user', {}).pipe(
      map((res) => res || res),
      catchError((error) => throwError(error))
    );
  }

  getAllCountries() {
    return this.http.get(environment.corePath + '/countries').pipe(
      map((res) => res || []),
      catchError((error) => throwError(error))
    );
  }
  getApplicableCountries() {
    return this.http.get(environment.offerPath + '/countries').pipe(
      map((res) => res || []),
      catchError((error) => throwError(error))
    );
  }
  getAllInternationalCountries() {
    return this.http
      .get(environment.offerPath + '/international_offers/countries')
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  getAllCities(params) {
    return this.http
      .get(environment.corePath + '/cities', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  getAllInternationalCities(params) {
    return this.http
      .get(environment.offerPath + '/international_offers/cities', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  getAllLocalities(params) {
    return this.http
      .get(environment.corePath + '/localities', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getCards(params = {}) {
    return this.http
      .get<any>(environment.corePath + '/user/user_cards', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  fetchCards(params = {}) {
    return this.http
      .get<any>(environment.corePath + '/user/user_cards/fetch', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getCardsWithPoints(params = {}) {
    return this.http
      .get<any>(environment.corePath + '/user_card', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  binValidation(bin) {
    return this.http
      .get<any>(environment.offerPath + '/bins/card_details?bin=' + bin)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  cardValidation(bin) {
    return this.http
      .post<any>(environment.corePath + '/bins/validate', { bin: bin })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  addCard(bin, bin_type) {
    return this.http
      .post<any>(environment.corePath + '/user/user_cards', {
        bin: bin,
        bin_type: bin_type,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  validateCard(bin, bin_type) {
    return this.http
      .get<any>(
        environment.corePath +
          '/user/user_cards/search?bin=' +
          bin +
          '&bin_type=' +
          bin_type
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getRedemptions(params = {}) {
    const httpOptions = {
      headers: new HttpHeaders({
        exclude_project_id: 'true',
      }),
    };
    return this.http
      .get(environment.offerPath + '/redemptions?offer=true', httpOptions)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getClient(v2 = false) {
    var cpath = v2 == false ? environment.corePath : environment.corePathV2;
    return this.http.get(cpath + '/client').pipe(
      map((res) => res || []),
      catchError((error) => throwError(error))
    );
  }

  getOffers(params, isNearBy?, isInternational?) {
    return this.http
      .get(environment.offerPath + '/offers?elgble=true', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getCategories(params = {}) {
    return this.http
      .get(environment.offerPath + '/categories?elgble=true', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getCategoryDetails(params = {}) {
    return this.http
      .get(environment.offerPath + '/category_details?elgble=true', {
        params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getCategoryDetail(id, params) {
    return this.http
      .get(environment.offerPath + '/category_details/' + id + '?elgble=true', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getMerchants(params) {
    return this.http
      .get(environment.offerPath + '/merchants?elgble=true', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  getNearByMerchants(params) {
    return this.http
      .get(environment.offerPath + '/nearby_offers/merchants?elgble=true', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  searchMerchants(params) {
    return this.http
      .get(environment.offerPath + '/merchants?elgble=true', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  searchMerchantsV2(params: any) {
    return this.http
      .get(environment.offerPath + '/merchants/search', {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  searchGiftcards(params: any) {
    return this.http
      .get(environment.offerPathV2 + '/gift_cards?search=' + params)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  redeem(offer, params) {
    return this.http
      .post(
        environment.offerPath + '/offers/' + offer.id + '/redeem?elgble=true',
        params
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  redeemByBin(offer, bin) {
    return this.http
      .post(
        environment.offerPath + '/offers/' + offer.id + '/redeem?elgble=true',
        { bin: bin }
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  createTokenUser(token, uid?, client?, attr = 'token') {
    let params = {};
    if (token) params[attr] = token;
    if (uid) params['uid'] = uid;
    if (client) params['client'] = client;
    if (this.GlobalConstants.data.api_version == 'v1') {
      return this.http
        .post(environment.corePath + '/sessions/create_with_user', params)
        .pipe(
          map((res) => res || []),
          catchError((error) => throwError(error))
        );
    } else {
      return this.http.post(environment.corePath + '/sessions', params).pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
    }
  }

  checkCardEligibility(bin, bin_type, bundle_id) {
    return this.http
      .get(
        environment.offerPath +
          '/bins/validate?bin=' +
          bin +
          '&bundle_id=' +
          bundle_id
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getBanners(params) {
    return this.http
      .get(environment.offerPathV2 + '/client_banners', params)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getCampaignBanners() {
    return this.http.get(environment.campaignPath + 'campaigns/banners').pipe(
      map((res) => res || []),
      catchError((error) => throwError(error))
    );
  }

  getTerms(id) {
    return this.http
      .get(environment.offerPathV2 + '/offers/' + id + '/terms')
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  checkPinCode(pin) {
    return this.http.get(`https://api.postalpincode.in/pincode/${pin}`).pipe(
      map((res) => res || []),
      catchError((error) => throwError(error))
    );
  }
  checkCordinates(cordinates) {
    return this.http
      .get(
        `https://api.opencagedata.com/geocode/v1/json?q=${cordinates}&key=d73135d9826a49ef91a2bf334da1be37`
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  getProjects() {
    const httpOptions = {
      headers: new HttpHeaders({
        exclude_project_id: 'true',
      }),
    };
    return this.http
      .get(`${environment.offerPathV2}/eligible_projects`, httpOptions)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  getPaymentLink(params) {
    return this.http
      .post<any>(environment.apiUrlPaV1 + 'payments', params)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  updateUserCreds(params) {
    return this.http.put(environment.corePathV2 + '/user', params).pipe(
      map((res) => res || []),
      catchError((error) => throwError(error))
    );
  }
  confirmEmail(params) {
    return this.http
      .put(environment.corePathV2 + '/user/verify_email', params)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  resendEmail() {
    return this.http
      .post<any>(environment.corePathV2 + '/user/resend_email_verification', {})
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  getIpStackLocation() {
    return this.http
      .get(
        `https://api.ipstack.com/check?access_key=${environment.ipstackKey}&format=1`
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  addLocation(params) {
    return this.http
      .post<any>(environment.corePath + '/user/user_locations/log', params)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  getApplicableLocalities(id) {
    return this.http
      .get(environment.offerPath + `/nearby_offers/localities?state_id=${id}`)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  getApplicableCities() {
    return this.http.get(environment.offerPath + '/nearby_offers/states').pipe(
      map((res) => res || []),
      catchError((error) => throwError(error))
    );
  }

  getBookingDetailsById(id) {
    return this.http
      .get(
        environment.stagingUrl +
          `users/bookings/booking_details?booking_unique_id=${id}`
      )
      .toPromise();
  }

  getCampaign(campaignId) {
    return this.http
      .get(environment.campaignEnterprisePath + `campaigns/${campaignId}`, {})
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getCampaignInappCommunicationSettings(campaignId, triggerId) {
    return this.http
      .get(
        environment.campaignEnterprisePath +
          `campaigns/` +
          campaignId +
          `/trigger_settings/` +
          triggerId +
          `/in_app_communication_settings`,
        {}
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  // getCampaignOffers(campaignId) {
  // 	let currentUserCard = JSON.parse(localStorage.getItem('userCard'));
  // 	let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  // 	return this.http.get(environment.campaignPath + `campaigns/`+campaignId+`/offer_redemption_options`).pipe(
  // 		map(res => res || []),
  // 		catchError(error => of(error))
  // 	);
  // }

  getCampaignMemberships(campaignId) {
    let currentUserCard = JSON.parse(localStorage.getItem('userCard'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http
      .get(
        environment.campaignPath +
          `campaigns/` +
          campaignId +
          `/membership_redemption_options`
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  public getCampaignMembershipDetails(
    campaignId: string,
    membershipId: string
  ): Observable<MembershipDetailResponse> {
    return this.http.get<MembershipDetailResponse>(
      `${environment.campaignPath}campaigns/${campaignId}/membership_redemption_options/${membershipId}`
    );
  }

  public getCampaignGiftcardDetails(
    campaignId: string,
    giftcardId: string
  ): Observable<GiftCardDetailResponse> {
    return this.http.get<GiftCardDetailResponse>(
      `${environment.campaignPath}campaigns/${campaignId}/gift_card_redemption_options/${giftcardId}`
    );
  }

  getCampaignGiftcards(campaignId) {
    // let currentUserCard = JSON.parse(localStorage.getItem('userCard'));
    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return this.http
      .get(
        environment.campaignPath +
          `campaigns/` +
          campaignId +
          `/gift_card_redemption_options`
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getCampaignDetails(campaignId) {
    // let currentUserCard = JSON.parse(localStorage.getItem('userCard'));
    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return this.http
      .get(environment.campaignPath + `campaigns/` + campaignId)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getCampaignRedemptions(params: any = null) {
    // let currentUserCard = JSON.parse(localStorage.getItem('userCard'));
    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return this.http
      .get(environment.offerPathV2 + `/redemptions?campaign=true`, {
        params: params,
      })
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  getCampaigns() {
    // let currentUserCard = JSON.parse(localStorage.getItem('userCard'));
    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return this.http
      .get(environment.campaignPath + `campaigns?with_expired_benefits=true`)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  redeemCampaignGiftcard(campaignId, giftcardId, formData) {
    let params = new HttpParams({
      fromObject: formData,
    });
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
    return this.http
      .post(
        environment.campaignPath +
          `/campaigns/` +
          campaignId +
          `/gift_card_redemption_options/` +
          giftcardId +
          `/redeem`,
        params.toString(),
        httpOptions
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  initiateRedemption(campaignId, data) {
    // let params = new HttpParams({
    // 	fromObject: formData,
    // });
    // let httpOptions = {
    // headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    // };
    return this.http
      .post(
        environment.campaignPath +
          `/campaigns/` +
          campaignId +
          `/user_redemptions/initiate_redeem_payment`,
        data
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  redeemCampaignMembership(campaignId, membershipId, formData) {
    let params = new HttpParams({
      fromObject: formData,
    });
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
    return this.http
      .post(
        environment.campaignPath +
          `/campaigns/` +
          campaignId +
          `/membership_redemption_options/` +
          membershipId +
          `/redeem`,
        params.toString(),
        httpOptions
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  public getDetailsViaPinCode(pinCode: string) {
    return this.http
      .get(
        environment.apiUrlBsV1Internal +
          '/country_details/get_details_by_pin?pin_code=' +
          pinCode
      )
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  public getMembershipTnc(id) {
    return this.http
      .get(environment.offerPathV2 + '/memberships/' + id + '/terms')
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  public getFormMetaData(): Observable<FormMetaDataResponse> {
    return this.http.get<FormMetaDataResponse>(
      `${environment.apiUrlBsV1}/concierge/form_data`
    );
  }

  public getAirports(
    value: string,
    serviceType: string
  ): Observable<Airport[]> {
    if (value) {
      return this.http.get<Airport[]>(`${environment.apiUrlBsV1}airports`, {
        params: { service: serviceType, search_for: value },
      });
    }

    return this.http.get<Airport[]>(`${environment.apiUrlBsV1}airports`, {
      params: { service: serviceType, top_cities: true },
    });
  }

  public getAirlines(searchKeyword: string): Observable<AirlinesReponse> {
    return this.http.get<AirlinesReponse>(
      `${environment.apiUrlBsV1}/airlines/search`,
      {
        params: {
          search_for: searchKeyword,
        },
      }
    );
  }

  // TODO: add types
  public submitConciergeForm(params: CampaignApiParams): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrlBsV1}/concierge/enquiry`,
      params
    );
  }

  public getMeetAndGreet(): Observable<BookingResponse> {
    return this.http.get<BookingResponse>(
      `${environment.apiUrlBsV1}/bookings?type=ConciergeRequest`
    );
  }

  public checkConciergeEligibility(
    category: string
  ): Observable<CampaignEligibilityResponse> {
    return this.http.get<CampaignEligibilityResponse>(
      `${environment.apiUrlBsV1}concierge/check_eligibility?category=${category}`
    );
  }
}
