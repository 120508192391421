import { Component, EventEmitter, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/pages/common/global-variables';
import { ApiService } from 'src/app/pages/common/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'country-modal',
  templateUrl: './country-modal.component.html',
  styleUrls: ['./country-modal.component.scss'],
  providers: [ApiService, GlobalConstants],
})
export class CountryModalComponent implements OnInit {
  isLoading = true;
  hideEvent: EventEmitter<boolean> = new EventEmitter();
  allCountries: any;
  currentCountry: any;
  constructor(
    public GlobalConstants: GlobalConstants,
    private _apiService: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.currentCountry = JSON.parse(localStorage.getItem('userCountry'));
    this.allCountries = JSON.parse(localStorage.getItem('allCountries'));
    if (!this.allCountries || this.allCountries.length == 0) {
      this.isLoading = true;
      this._apiService.getAllCountries().subscribe((res: any) => {
        this.allCountries = res.data;
        localStorage.setItem('allCountries', JSON.stringify(this.allCountries));
        this.processCountries(this.allCountries);
        this.isLoading = false;
      }),
        (err: any) => {
          this.isLoading = false;
          this.toastr.error('Something went wrong. Please try again!');
        };
    } else {
      this.processCountries(this.allCountries);
      this.isLoading = false;
    }
  }

  hideModal() {
    this.hideEvent.emit(true);
  }

  selectCountry(country) {
    localStorage.setItem('userCountry', JSON.stringify(country));
    location.reload();
  }
  featuredCountryCodes = [
    'IND',
    'SGP',
    'ARE',
    'AUS',
    'USA',
    'GBR',
    'CAN',
    'SAU',
    'KWT',
    'QAT',
    'BHR',
    'OMN',
    'HKG',
    'NZL',
    'BGD',
  ];
  popularCountries = [];
  otherCountries = [];
  countrySearchTerm = '';
  processCountries(allCountries, countryCode?) {
    this.popularCountries = [];
    this.otherCountries = [];
    this.allCountries = allCountries;
    this.allCountries.sort(function (a, b) {
      var textA = a.attributes.name.toUpperCase();
      var textB = b.attributes.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    if (this.allCountries && this.allCountries.length > 0) {
      this.allCountries.forEach((obj) => {
        let mobile_length = obj.attributes.mobile_length.split('...');
        obj.attributes.min_mobile_digit = parseInt(mobile_length[0]);
        obj.attributes.max_mobile_digit = parseInt(mobile_length[1]);
        if (this.featuredCountryCodes.includes(obj.attributes.iso_code)) {
          this.popularCountries.push(obj);
        } else {
          this.otherCountries.push(obj);
        }
      });

      // sort popular countries array w.r.t featured array
      this.popularCountries.sort((a, b) => {
        return (
          this.featuredCountryCodes.indexOf(a.attributes.iso_code) -
          this.featuredCountryCodes.indexOf(b.attributes.iso_code)
        );
      });

      // alphabetically sort other countries
      this.otherCountries.sort(function (a, b) {
        var textA = a.attributes.name.toUpperCase();
        var textB = b.attributes.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      this.allCountries = this.popularCountries.concat(this.otherCountries);
      this.filteredCountries = this.allCountries;
    }
  }
  filteredCountries = [];
  filterItem(searchTerm) {
    if (searchTerm && searchTerm.length > 0) {
      this.filteredCountries = Object.assign([], this.allCountries).filter(
        (item) =>
          item.attributes.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >
          -1
      );
    }
  }
}
