import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TreasuresHomepageService {
  public benefitSelectionSubject = new Subject<string>();
  public toggleBenefitFormSubject = new Subject<Array<boolean | string>>();

  public emitBenefitSelection(benefit: string): void {
    this.benefitSelectionSubject.next(benefit);
  }

  public emitToggleBenefitForm(isOpen: boolean, action: string): void {
    this.toggleBenefitFormSubject.next([isOpen, action]);
  }

  public onToggleBenefitForm(): Observable<Array<boolean | string>> {
    return this.toggleBenefitFormSubject.asObservable();
  }

  public onBenefitSelection(): Observable<string> {
    return this.benefitSelectionSubject.asObservable();
  }
}
