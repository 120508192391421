import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeUnderscore' })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value
      .replace(/_/g, ' ')
      .replace(/-/g, ' ')
      .replace(/(?: |\b)(\w)/g, function (key) {
        return key.toUpperCase();
      });
  }
}
