import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GlobalConstants {
  client: any;
  data: any;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router
  ) {
    this.client = 'dbs';
    this.data = {
      logo: {
        dark: {
          url: './assets/DBS/DBS_logo.svg',
          footerurl: './assets/DBS/DBS_logo.svg',
          desktop_url: './assets/DBS/DBS_logo.svg',
          modal_url: './assets/DBS/DBS_logo.svg',
          favicon: 'https://images.poshvine.com/favicon/dbs.ico',
          head_logo_height: '21',
          coupon_modal_logo: './assets/DBS/DBS_logo.svg',
          white_logo: './assets/DBS/dbs_white-logo.png',
        },
        light: {
          url: './assets/dbslogo.png',
          footerurl: './assets/dbslogo.png',
          desktop_url: './assets/dbslogo.png',
          modal_url: './assets/dbslogo.png',
          favicon: 'https://images.poshvine.com/favicon/dbs.ico',
          head_logo_height: '21',
          coupon_modal_logo: './assets/dbslogo.png',
          white_logo: './assets/DBS_whiteLogo.webp',
        },
        mass: {
          url: './assets/DBS/DBS_logo.svg',
          footerurl: './assets/DBS/DBS_logo.svg',
          desktop_url: './assets/DBS/DBS_logo.svg',
          modal_url: './assets/DBS/DBS_logo.svg',
          favicon: 'https://images.poshvine.com/favicon/dbs.ico',
          head_logo_height: '21',
          coupon_modal_logo: './assets/DBS/DBS_logo.svg',
          white_logo: './assets/DBS/dbs_white-logo.png',
        },
      },
      assets: {
        img: {
          banner: [],
        },
        client_css: 'citi.scss',
        manifest: 'manifest.webmanifest',
      },
      links: {
        projectLink: 'https://dbs.poshvine.com/',
      },
      colors: {
        header_bg_color: '#fff',
        coupon_modal_bg_color: '#ffffff',
        offer_title_color: '#195993',
        main_text_color: '#262626',
        medium_text_color: '#5C5C5C',
        light_text_color: '#9D9D9D',
        main_bg_color: '#F4F4F4',
        active_card_bg_color: '#F4F4F4',
        seconday_bg_color: '#FDFCFA',
        mainBtn_color: '#FF3E3E',
        inactive_btn_color: '#D8D8D8',
        btn_positive_action_color: '#195993',
        active_text_color: '#EB483F',
      },
      legal_name: 'DBS Lifestyle Benefits',
      font_family: {
        link: 'https://images.poshvine.com/fonts/gotham-new/gotham-new.css',
        name: 'Gotham',
      },
      x_header: {
        prod: {
          client_id: 'b191c165-ffeb-4d55-81b9-6278b3c08bc5',
          main_project: '5c614407-968f-4e8f-8ff5-bbef5d4222b6',
          sub_project: 'a6a55986-d69d-4b91-ab5f-4168c787c9ab',
          alternate_project: '3bcb814c-d970-4947-83e6-e144c785bdf3',
          mass_project_id: '7bb9e1e0-f326-4a3f-bef9-4bc6674abb2a',
          discounted_project_id: "f657e4b1-7b3f-467e-bacf-fda6215a7c61",
          treasures_project_id: '453993ca-38ee-4e30-bd19-60504b38c2b1'
        },
        sandbox: {
          // 'client_id': 'b191c165-ffeb-4d55-81b9-6278b3c08bc5',
          // 'main_project': '5c614407-968f-4e8f-8ff5-bbef5d4222b6',
          // 'sub_project': 'a6a55986-d69d-4b91-ab5f-4168c787c9ab'
          client_id: '09fc67f1-b0f7-486e-9ef4-54cb6b74e89f',
          main_project: '532792e1-c583-4437-b6e0-a5827597568b',
          sub_project: 'a6a55986-d69d-4b91-ab5f-4168c787c9ab',
          alternate_project: 'baba683e-dfc4-40be-aff4-e323afd015fb',
          mass_project_id: '89a79f5f-ba9f-4db7-9986-0712c0069e1b',
          discounted_project_id: "4f616ed3-d045-49af-98c3-d0daecc85ccf",
          // 'alternate_project':'532792e1-c583-4437-b6e0-a5827597568b'
          treasures_project_id: '453993ca-38ee-4e30-bd19-60504b38c2b1'
        },
        default_card_type: 'credit',
      },
      search_type: 2,
      desktop_category_count: 6,
      card_num_type: 'first4_last4',
      card_type: 'Debit/Credit',
      tabs_type: 'category',
      tabs_type_mobile: 'category',
      has_exclusive: false,
      banner_type: 'static',
      login_button_needed: true,
      loginAt: 'card',
      login_theme: 1,
      login_steps: ['mobile', 'otp', 'card'],
      auto_expiry: {
        time: '',
        enabled: false,
      },
      template: 1,
      header_type: 'default',
      show_saving_for_bundle_codes: {},
      download_app_link: {
        header: false,
        footer: false,
        android_link: '',
        apple_link: '',
      },
      login_modal_type: 'desktop-prev-header',
      supported_devices: ['web', 'mobile'],
      benefits: ['offers'],
      banner_style: 1,
      carouselInterval: -1,
      api_version: 'v2',
      personalized_config: {
        coupon_terms_link: false,
        switch_card: false,
        autocard_check: false,
        view_savings: true,
        external_otp: false,
        redemption_page_needed: true,
        saving_page: false,
        resend_allowed: true,
        add_new_card: true,
        footer_logo: false,
        force_auth: false,
        token_auth: false,
        desktop_search: true,
        mobile_search: true,
        auth: true,
        coupon_modal: true,
        coupon_show: true,
        amount_show: false,
        modal_redeem_button: true,
        mobile_home_back_button: true,
        show_card_used: false,
        show_bundle_used: false,
        allow_user_create: true,
        dedicated_banner: false,
        mobile_banner: true,
        authenticate_before_claim: true,
        disclaimer_popup: false,
        cancelable_auth: true,
        display_bundle_category_title: true,
        mobile_login: true,
        is_concierge: false,
        has_card: true,
        home_subcategory_dropdown: false,
        home_subcategory_bar: false,
        has_near_by: false,
        has_international: false,
        mobile_profile_dropdown: true,
        category_page: true,
        authenticate_bin: true,
        datalayer: false,
        custom_gtm_tags: false,
        show_terms_before_redeem: false,
        details_desktop_tabs: false,
        show_special_category: false,
        send_project_id: true,
        show_card_applicability: false,
        card_applicability_fetch: 'api',
        show_card_select_on_auth: false,
        special_bundle: false,
        external_login_banner: false,
        has_international_offers: false,
        redemption_page_display_card_number: true,
        has_add_card_text: false,
        send_as_gift: false,
        applicableTag: false,
        has_maps: false,
      },
      subProjectLinks: ['paytm-wellnessoffers'],
      titles: {
        homepage_pagetitle: 'DBS Lifestyle Benefits',
        homepage_description: '',
        homepage_keywords: '',
        header_title: '',
        modal_mobile_label:
          'To access exclusive offers specially curated for you!',
        card_name: 'DBS Bank Credit Card',
        platform_title: 'DBS Lifestyle Benefits',
        concierge_name: 'DBS Lifestyle Benefits',
        login_modal_control:
          'Enter your registered mobile number to sign in to your existing account',
      },
      footer_additional_content:
        "<p>Disclaimer: This platform is curated for communication of personalized offers extended by Merchants to DBS's Customers. This platform is only communicating the offers extended by Merchants to its Customers and not Selling/Rendering any of these Products/Services. The platform neither guarantees nor makes any representation. This platfrom is not responsible for Sale/Quality/Features of the Products/Services under the offers.</p><p>Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE11+ at 1024 x 768 pixels resolution, or above.</p>",
      content: {
        bin_error: {
          first4_last4:
            'Dear customer, please re-check the first 4 and last 4 digits of the card number you have entered',
          first6:
            'Dear customer, please re-check the first 6 digits of your card number you have entered',
        },
        bin_invalid: {
          first4_last4:
            'Please enter your valid first 4 and last 4 digits of your debit card correctly. In case the issue persists, write to support@poshvine.com',
          first6: 'Invalid Card',
        },
      },
      email: {
        display: 'support.dbs@poshvine.com',
        val: 'support.dbs@poshvine.com',
      },
      phone: {
        display: '18008437889',
        val: '18008437889',
      },

    membership_details :{
      headers:["Brand" , "Treasures Silver" , "Treasures Gold" , "Treasures Platinum" , "TPC" ] ,
      brandsData:[
      {name:"Luxe Brand Gift vouchers" , treasures:["2500" , "5000" , "7500" , "10000"]} ,
      {name:"WeWork Pass" , treasures:["NA" , "NA" , "1/Quarter" , "2/Quarter"]} ,
      {name:"Eazy Diner Membership" , treasures:["1" , "1" , "1" , "1"]} ,
      {name:"Times Prime Membership" , treasures:["1" , "1" , "1" , "1"]} ,
      {name:"Samarth Elderly care" , treasures:["1" , "1" , "1" , "1"]} ,
      {name:"Priority Check In" , treasures:["1/year" , "2/year" , "3/year" , "4/year"]} ,
      {name:"Cult.fit Fitness membership" , treasures:["3-month membership" , "3-month membership" , "6-month membership" , "12-month membership"]} ,
    ]
    }

    };

  }
}
