import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailMask',
})
export class EmailMaskPipe implements PipeTransform {
  transform(value: string): string {
    if (value.length < 10) {
      return value;
    }
    let lastpart = '@' + value.split('@')[1];
    let firstpart = value.split('@')[0].substr(0, 3) + '***';

    return firstpart + lastpart;
  }
}
