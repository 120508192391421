import { ThemeService } from 'src/app/shared/services/theming.service';
import { Injectable, EventEmitter } from '@angular/core';
// import { HttpClient } from './http-client';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject, BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AESEncryptDecryptService } from 'src/app/authentication/encryption/AESEncryptDecryptService.service';
import { User } from './user.modal';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthenticationComponent } from './authentication.component';
import { ApiService } from '../pages/common/api.service';
import { appConstants } from '../app.constants';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public errorEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public reloadInit: EventEmitter<boolean> = new EventEmitter<boolean>();
  public reloadAuth: EventEmitter<boolean> = new EventEmitter<boolean>();
  public updatePoints: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  public isUserLoggedIn: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  public isAuthenticationComponentOpen: boolean = false;
  public isCurrentDomainTreasures: boolean = window.location.hostname.includes(
    appConstants.dbsTreasuresUrlKeyword
  );

  constructor(
    private http: HttpClient,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    public matDialog: MatDialog,
    public _apiService: ApiService,
    protected themeService: ThemeService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();

    environment.corePath = environment.corePathV2;
    environment.offerPath = environment.offerPathV2;

    this.errorEvent.subscribe((res) => {
      if (res && !this.isAuthenticationComponentOpen) {
        this.isAuthenticationComponentOpen = true;
        this.matDialog
          .open(AuthenticationComponent, {
            panelClass: 'authenticationModal',
            data: {
              callbackOnClose: true,
            },
          })
          .afterClosed()
          .subscribe((res) => {
            if (res) {
              location.reload();
            }
            this.isAuthenticationComponentOpen = false;
          });
      }
    });
  }

  public get currentUserValue(): any {
    return this.http.get<any>(environment.corePath + '/user');
    map((res) => {
      return res;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      });
  }

  getCurrentUser(bin, bin_type) {
    return this.http.get<any>(environment.corePath + '/user').pipe(
      map((res) => res || []),
      catchError((error) => throwError(error))
    );
  }

  validateExistingUser(mobile: string, calling_code: string) {
    return this.http
      .post<any>(environment.corePath + '/users/search', {
        mobile: mobile,
        calling_code: calling_code,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  createUser(mobile: string, email: string, country_id: string = 'default') {
    if (country_id == 'default') {
      return this.http
        .post<any>(environment.corePath + '/users', {
          mobile: mobile,
          email: email,
        })
        .pipe(
          map((res) => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    } else {
      return this.http
        .post<any>(environment.corePath + '/users', {
          mobile: mobile,
          email: email,
          country_id: country_id,
        })
        .pipe(
          map((res) => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }
  }

  requestUserOtp(mobile: string, userId: string) {
    return this.http
      .post<any>(environment.corePath + '/users/' + userId + '/otps', {
        mobile: mobile,
        otp_type: 'login',
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  validateUserOtp(
    userId: string,
    otp: string,
    otpType: string,
    mobileNum?: any
  ) {
    return this.http
      .post<any>(environment.corePath + '/users/' + userId + '/otps/verify', {
        otp_type: otpType,
        code: otp,
      })
      .pipe(
        map((res) => {
          var user = {
            id: userId,
            token: res.token,
          };

          // if (mobileNum) {
          //   user['mobile'] = mobileNum;
          // }
          if (user && user.token) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(res);
          }

          return user;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('card_verified');
    localStorage.removeItem('cardSelected');
    localStorage.removeItem('eligible_banners');
    localStorage.removeItem('locationData');
    localStorage.removeItem('currentUserCard');
    localStorage.removeItem('userCard');
    localStorage.removeItem('userCards');
    localStorage.removeItem('expire_at');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('thebin');
    const firstLoad = localStorage.getItem('firstLoad');
    const userCountry = localStorage.getItem('userCountry');
    const redirectData = localStorage.getItem('redirectData');
    sessionStorage.clear();
    if (userCountry) {
      localStorage.setItem('userCountry', userCountry);
    }
    this.themeService.setThemes('light');
    this.errorEvent.emit(true);
    if (firstLoad) {
      this.reloadInit.emit(true);
      localStorage.removeItem('firstLoad');
    }
    if (redirectData) {
      localStorage.setItem('redirectData', redirectData);
    }

    this.currentUserSubject.next(null);
  }

  deleteToken() {
    if (JSON.parse(localStorage.getItem('currentUser'))) {
      var currentUserId = JSON.parse(localStorage.getItem('currentUser')).id;
    }
    return this.http
      .post<any>(environment.corePath + '/sessions/' + currentUserId, {})
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  clearAuthStorage() {
    this.logout();
    sessionStorage.clear();
  }

  processDataQuery(params) {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('card_verified');
    localStorage.removeItem('cardSelected');
    localStorage.removeItem('eligible_banners');
    localStorage.removeItem('locationData');
    localStorage.removeItem('currentUserCard');
    localStorage.removeItem('userCard');
    localStorage.removeItem('userCards');
    localStorage.removeItem('expire_at');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('thebin');
    if (params.cf) {
      let channel_ref = params.cf.toLowerCase();
      localStorage.setItem('channel_ref', channel_ref);
      if (channel_ref == 'mb') {
        localStorage.setItem('appUser', 'true');
      }
    }
    if (
      params.tk &&
      params.tk != 'no-token' &&
      !this.isCurrentDomainTreasures
    ) {
      let urlcode = params.tk;
      localStorage.setItem('card_verified', 'true');
      localStorage.setItem('urlcode', urlcode);
      var user = {
        token: urlcode,
        skipExceptCard: true,
      };
      localStorage.setItem('currentUser', JSON.stringify(user));
      this._apiService.getUserDetails().subscribe(
        (res: any) => {
          user['id'] = res.id;
          localStorage.setItem('userDetails', JSON.stringify(res));
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.updatePoints.emit(true);
          this._apiService
            .getCardsWithPoints({ fetch_points: true })
            .subscribe((response) => {
              if (response && response.data && response.data.length > 0) {
                let card = response.data[0];
                localStorage.setItem('userCard', JSON.stringify(card));
                localStorage.setItem(
                  'userCards',
                  JSON.stringify(response.data)
                );
                this.isUserLoggedIn.emit(true);
              } else {
                this.isUserLoggedIn.emit(true);
              }
            });
        },
        (err) => {
          this.clearAuthStorage();
        }
      );
    }
  }
}
