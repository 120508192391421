<section class="showCountries pad-30">
  <div class="close-box pointer heading text-center fnt-22 f600">
    Select Country
    <div class="absolute pointer modal-close-svg" *ngIf="currentCountry">
      <svg
        (click)="hideModal()"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 1.51071L13.4893 0L7.5 5.98929L1.51071 0L0 1.51071L5.98929 7.5L0 13.4893L1.51071 15L7.5 9.01071L13.4893 15L15 13.4893L9.01071 7.5L15 1.51071Z"
          fill="#919aa2"
        />
      </svg>
    </div>
  </div>
  <div
    class="select-input-parent pad-bot-8 pad-left-5 pad-right-5 w100 relative"
  >
    <input
      autocomplete="new-password"
      class="select-input form-control fnt-13"
      [(ngModel)]="countrySearchTerm"
      [ngModelOptions]="{ standalone: true }"
      (input)="filterItem(countrySearchTerm)"
      placeholder="Search country"
    />
    <svg
      class="absolute se-ic"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0994 20.1942C12.5711 20.1935 14.9558 19.2805 16.7974 17.6299L22.9867 23.8266C23.0982 23.9378 23.2492 24.0001 23.4065 24C23.5639 23.9998 23.7148 23.9372 23.826 23.8258C23.9373 23.7144 23.9999 23.5634 24 23.4058C24.0001 23.2483 23.9379 23.0971 23.8268 22.9855L17.6374 16.7888C19.1667 15.0587 20.0571 12.856 20.1601 10.5481C20.2632 8.24013 19.5726 5.96661 18.2037 4.10686C16.8347 2.2471 14.8702 0.913592 12.6379 0.328823C10.4055 -0.255945 8.0404 -0.0566021 5.93713 0.893592C3.83385 1.84379 2.11964 3.48736 1.08051 5.55008C0.0413807 7.6128 -0.259817 9.9699 0.227173 12.2281C0.714163 14.4863 1.95989 16.509 3.75649 17.9587C5.55309 19.4084 7.79192 20.1975 10.0994 20.1942ZM10.0994 1.16102C11.8619 1.16102 13.5848 1.68428 15.0502 2.66462C16.5156 3.64496 17.6578 5.03835 18.3323 6.6686C19.0067 8.29885 19.1832 10.0927 18.8394 11.8234C18.4955 13.5541 17.6468 15.1438 16.4006 16.3915C15.1543 17.6392 13.5665 18.489 11.8379 18.8332C10.1093 19.1775 8.31754 19.0008 6.68923 18.3255C5.06092 17.6502 3.66918 16.5067 2.69001 15.0395C1.71084 13.5723 1.1882 11.8474 1.1882 10.0828C1.19086 7.71744 2.13057 5.44969 3.80117 3.7771C5.47176 2.10451 7.73682 1.16368 10.0994 1.16102Z"
        fill="#777"
      />
    </svg>
  </div>
  <div class="main-body flex" *ngIf="!isLoading && allCountries">
    <div
      class="country-block pad-5"
      *ngFor="let country of filteredCountries"
      (click)="selectCountry(country)"
    >
      <div
        class="country-block-in flex align-center pointer 100 pad-5 pad-top-6 pad-bot-6"
      >
        <img
          class="img-fluid country-flag"
          [src]="country?.attributes.flag_url"
        />
        <span
          class="fnt-14 pad-left-7 country-name ellipsis"
          [innerHtml]="country?.attributes.name"
        ></span>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="flex align-center justify-center loader-parent">
    <div class="page-loader"></div>
  </div>
</section>
