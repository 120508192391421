<div class="relative" *ngIf="showIfNotAuthenticated">
  <ng-container *ngIf="isRelogin">
    <!-- <span class="session-timeout">Oops! Your session has timed out. Please authenticate to continue browsing.</span> -->
    <br />
    <br />
  </ng-container>
  <div class="close-box pointer mar-bot-20">
    <img
      [src]="isCurrentDomainTreasures ? treasuresImages.treasuresLogoDark : '../../assets/DBS/DBS_logo.svg'"
      class="img-fluid login-client-logo"
      [ngClass]="isCurrentDomainTreasures ? 'treasures-logo' : 'logo'"
      (click)="closeAuthentication()"
    />
  </div>
  <section class="step-one" *ngIf="stepToShow == 'mobile'">
    <form
      [formGroup]="createWithUserForm"
      (submit)="authenticateUser($event)"
      autocomplete="newpassword"
    >
      <div class="title">
        Enter your registered mobile number to sign in to your existing account
      </div>
      <div class="row fields pad-top-20">
        <div class="col-sm-12">
          <div class="clearfix field-wrap">
            <label class="mar-bot-10">
              <span
                *ngIf="
                  GlobalConstants.data.titles.modal_mobile_label == '' ||
                  GlobalConstants.data.titles.modal_mobile_label == null
                "
              >
                Mobile Number
              </span>
              <span
                *ngIf="
                  GlobalConstants.data.titles.modal_mobile_label != '' &&
                  GlobalConstants.data.titles.modal_mobile_label != null
                "
              >
                {{ GlobalConstants.data.titles.modal_mobile_label }}
              </span>
            </label>
            <div class="mobile-block">
              <div class="country-block flex allign-center justify-center">
                <div
                  class="searchdrop relative"
                  dropdown
                  #dropdown="bs-dropdown"
                  [insideClick]="true"
                >
                  <div
                    class="w100 h100 flex align-center pointer fnt-16 f500"
                    dropdownToggle
                  >
                    <div
                      class="custom-select-box relative"
                      *ngIf="selectedCountry"
                    >
                      <div class="filter-btn">
                        <img
                          [src]="selectedCountry.flag_url"
                          alt="Flag"
                          class="filter-ic relative selected-country-flag"
                        />
                        <div class="dropdown-icon">
                          <svg
                            class="left"
                            width="8"
                            height="4"
                            viewBox="0 0 8 4"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 4L0 0L8 6.28116e-07L4 4Z"
                              fill="#98A2B4"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="dropdown-menu dropdown-menu-shadow w100 country-list mar-top-6 pad-top-0"
                    *dropdownMenu
                  >
                    <div class="select-input-parent pad-8 w100 z1 bg-fff">
                      <input
                        autocomplete="new-password"
                        class="select-input form-control fnt-13"
                        [(ngModel)]="countrySearchTerm"
                        [ngModelOptions]="{ standalone: true }"
                        (input)="filterItem(countrySearchTerm)"
                        placeholder="Search country"
                      />
                      <svg
                        class="absolute se-ic"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0994 20.1942C12.5711 20.1935 14.9558 19.2805 16.7974 17.6299L22.9867 23.8266C23.0982 23.9378 23.2492 24.0001 23.4065 24C23.5639 23.9998 23.7148 23.9372 23.826 23.8258C23.9373 23.7144 23.9999 23.5634 24 23.4058C24.0001 23.2483 23.9379 23.0971 23.8268 22.9855L17.6374 16.7888C19.1667 15.0587 20.0571 12.856 20.1601 10.5481C20.2632 8.24013 19.5726 5.96661 18.2037 4.10686C16.8347 2.2471 14.8702 0.913592 12.6379 0.328823C10.4055 -0.255945 8.0404 -0.0566021 5.93713 0.893592C3.83385 1.84379 2.11964 3.48736 1.08051 5.55008C0.0413807 7.6128 -0.259817 9.9699 0.227173 12.2281C0.714163 14.4863 1.95989 16.509 3.75649 17.9587C5.55309 19.4084 7.79192 20.1975 10.0994 20.1942ZM10.0994 1.16102C11.8619 1.16102 13.5848 1.68428 15.0502 2.66462C16.5156 3.64496 17.6578 5.03835 18.3323 6.6686C19.0067 8.29885 19.1832 10.0927 18.8394 11.8234C18.4955 13.5541 17.6468 15.1438 16.4006 16.3915C15.1543 17.6392 13.5665 18.489 11.8379 18.8332C10.1093 19.1775 8.31754 19.0008 6.68923 18.3255C5.06092 17.6502 3.66918 16.5067 2.69001 15.0395C1.71084 13.5723 1.1882 11.8474 1.1882 10.0828C1.19086 7.71744 2.13057 5.44969 3.80117 3.7771C5.47176 2.10451 7.73682 1.16368 10.0994 1.16102Z"
                          fill="#777"
                        />
                      </svg>
                    </div>
                    <div class="block loc-results">
                      <div
                        *ngFor="let country of filteredCountries; let i = index"
                        (click)="
                          dropdown.isOpen = false;
                          countrySearchTerm = '';
                          setCountry(country);
                          userSearch()
                        "
                        [ngClass]="{
                          'selected-country':
                            country.attributes?.iso_code ==
                            selectedCountry.iso_code
                        }"
                      >
                        <div class="flex relative country-item pointer">
                          <div class="country-img">
                            <img
                              [src]="country.attributes.flag_url"
                              class="img-fluid"
                            />
                          </div>
                          <div
                            class="item-name flex align-center c0E202E fnt-14 w100"
                          >
                            <span>{{ country.attributes.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <span class="icon-down-open-mini"></span> -->
                </div>
              </div>

              <div class="mobile-input relative">
                <input
                  formControlName="mobile"
                  class="form-control h100"
                  [(ngModel)]="mobNum"
                  type="text"
                  placeholder="Mobile Number"
                  maxlength="{{ selectedCountry?.max_mobile_digit }}"
                  minlength="{{ selectedCountry?.min_mobile_digit }}"
                  (input)="
                    removeLetter($event.target.value);
                    debouncedUserSearch($event)
                  "
                />
                <div class="small-loader input-load" *ngIf="searchLoader"></div>
              </div>
            </div>
          </div>

          <div
            class="clearfix field-wrap mar-top-20 email-block"
            *ngIf="!ifExistingUser"
          >
            <!-- <div>
              <label> {{ GlobalConstants.data.titles.modal_email_label ||
                'Enter your email address'}}</label>
              <div class="input-block">
                <input formControlName="email" class="form-control" type="text"
                  (input)="errorRequestingOtpMsg = ''; isload1 = false;" />
              </div>
              <div *ngIf="f.email.errors && submittedForm1" class="error-txt fnt-12 f500 mar-top-5">
                Please enter a valid email address
              </div>
            </div> -->
            <div class="error-txt mar-top-10">
              Please enter registered mobile number
            </div>
          </div>

          <div class="clearfix relative">
            <div class="error-txt mar-top-10" *ngIf="errorRequestingOtp">
              {{
                errorRequestingOtpMsg === 'invalid_user_details'
                  ? 'Invalid credentials'
                  : errorRequestingOtpMsg
              }}
            </div>
          </div>
          <div>
            <div class="clearfix continue-btn-block mar-top-20">
              <button
                class="relative"
                type="submit"
                [ngStyle]="{ background: 'var(--primary-btn)' }"
                *ngIf="mobileInputBtnActive"
                [disabled]="isload1"
              >
                Continue
                <div
                  class="small-loader input-load merged-card-loader"
                  *ngIf="isload1"
                ></div>
              </button>

              <button
                type="btn"
                *ngIf="!mobileInputBtnActive"
                disabled="disabled"
              >
                Continue
              </button>
              <!-- <div class="close-authentication pointer underline"
                *ngIf="GlobalConstants.data.personalized_config.cancelable_auth"
                (click)="closeAuthentication()">I'll do this later ></div> -->
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
  <section class="step-two otp-input" *ngIf="stepToShow == 'otp'">
    <form [formGroup]="validateUserOtpForm">
      <div class="title" style="font-size: 16px">
        <span>
          Enter the 6 digit OTP sent to
          <span
            class="pad-right-4"
            *ngIf="
              selectedCountry &&
              selectedCountry.calling_codes &&
              selectedCountry.calling_codes[0]
            "
          >
            +{{ selectedCountry.calling_codes[0] }}
          </span>
          *******{{ mobNum | slice: 7 : 10 }}
        </span>
        <span
          class="back-btn pointer relative fnt-13 pad-left-5"
          [ngStyle]="{ color: 'var(--primary-btn)' }"
          (click)="resetLoginAgain()"
        >
          (Edit)
        </span>
      </div>
      <div *ngIf="email">
        <label class="mar-bot-10">
          You'll also receive the OTP on your registered Email ID:
          <span class="otp-span f500" class="">{{ email | emailMask }}</span>
        </label>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="otp-field">
            <input
              formControlName="otp"
              id="otp-field"
              class="form-control"
              autocomplete="off"
              (input)="validateUserOtp(); invalidOtp = false"
              max="6"
              maxlength="6"
              [(ngModel)]="otpInp"
            />
            <div class="input-border flex">
              <span
                class="num-1"
                [ngClass]="{ active: otpInp?.length == 0 || !otpInp }"
              ></span>
              <span
                class="num-2"
                [ngClass]="{ active: otpInp?.length == 1 }"
              ></span>
              <span
                class="num-3"
                [ngClass]="{ active: otpInp?.length == 2 }"
              ></span>
              <span
                class="num-4"
                [ngClass]="{ active: otpInp?.length == 3 }"
              ></span>
              <span
                class="num-5"
                [ngClass]="{ active: otpInp?.length == 4 }"
              ></span>
              <span
                class="num-6"
                [ngClass]="{ active: otpInp?.length == 5 }"
              ></span>
            </div>
            <div class="just"></div>
            <div class="check" *ngIf="validOtp">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM6 11.25L2.25 7.5L3.3075 6.4425L6 9.1275L11.6925 3.435L12.75 4.5L6 11.25Z"
                  fill="#15C9B7"
                />
              </svg>
            </div>
            <div
              class="clear pointer"
              (click)="clearOtp(); invalidOtp = false"
              *ngIf="!otpVerifying"
            >
              X
            </div>
            <div class="clear otp-verifying" *ngIf="otpVerifying">
              <img class="loader" src="./assets/img/icons/spin.gif" />
            </div>
          </div>
          <div class="help-block f500 fnt-13 mar-top-12" *ngIf="viewTimer">
            Resend Code in {{ timerToShow }}
          </div>
          <div class="check error-txt" *ngIf="invalidOtp">
            Entered Otp is invalid.
          </div>
          <div class="check error-txt" *ngIf="errorRequestingOtp">
            {{ errorRequestingOtpMsg }}
          </div>
          <button
            [ngStyle]="{
              color: 'var(--primary-btn)',
              'border-color': 'var(--primary-btn)'
            }"
            (click)="
              clearOtp();
              authenticateUser($event, false);
              invalidOtp = false;
              errorRequestingOtp = false
            "
            class="resend-otp pointer fnt-12 f400 mar-top-4"
            *ngIf="!viewTimer || viewTimer.length == 0"
          >
            Resend Code
          </button>
        </div>
      </div>
    </form>
  </section>
  <section class="step-three" *ngIf="stepToShow == 'card'">
    <form [formGroup]="addCardForm" (submit)="addCardFormSubmit()">
      <div class="title">
        Enter first 4 & last 4 digits of your
        {{ GlobalConstants.data.titles.card_name }}
      </div>

      <div class="card-block">
        <label>Card Number</label>
        <div class="flex main-flex">
          <div class="flex-3 pd-r7">
            <input
              type="text"
              #firstFocusable
              id="first_four_card_digits"
              class="form-control"
              (keyup)="onKeyph($event); keytab($event, focusable)"
              formControlName="binFirst4"
              autocomplete="new-password"
              minlength="4"
              maxlength="4"
              (input)="checkbtn()"
              required
            />
          </div>
          <div class="flex-3 pd-r7">
            <div class="form-single-container">
              <input
                type="text"
                class="form-control text-center disabled"
                placeholder="XXXX"
                autocomplete="none"
                disabled
              />
            </div>
          </div>
          <div class="flex-3 pd-r7">
            <div class="form-single-container">
              <input
                type="text"
                class="form-control text-center disabled"
                (keyup)="onKeyph($event)"
                placeholder="XXXX"
                disabled
              />
            </div>
          </div>
          <div class="flex-3 pd-l7">
            <input
              type="text"
              #focusable
              id="last_four_card_digits"
              class="form-control"
              formControlName="binLast4"
              autocomplete="new-password"
              minlength="4"
              maxlength="4"
              (keyup)="onKeyph($event); backkeytab($event, firstFocusable)"
              (input)="checkbtn()"
              required
            />
          </div>
        </div>
      </div>
      <div class="modal-foot mar-bot-50 pad-bot-20 clearfix">
        <div class="error-txt fnt-12 f500 pad-top-10" *ngIf="binError">
          <span *ngIf="notApplicableCard">
            Sorry, offer not applicable on the card number you have entered
          </span>
          <span *ngIf="!notApplicableCard">
            Dear customer, please re-check the first 4 & last 4 digits of the
            card number you have entered
          </span>
        </div>

        <!-- <button class="btn btn-theme" *ngIf="step==2" (click)="authModal.hide();closeModal()">Submit</button> -->
        <!-- <div *ngIf="binError">
          <button class="btn btn-theme relative" (click)="clearCardInput()"
            [ngStyle]="{'background': 'var(--primary-btn)'}">Try Again</button>
        </div> -->
        <!-- <div *ngIf="!binError">
          <button class="btn btn-theme " *ngIf="!btnActive" type="submit" disabled="disabled">Continue</button>
          <button class="btn btn-theme relative" *ngIf="btnActive" type="submit"
            [ngStyle]="{'background': 'var(--primary-btn)'}">Continue
            <div class="small-loader input-load merged-card-loader" *ngIf="isload2"></div>
          </button>
        </div> -->

        <div
          class="mar-top-30"
          *ngIf="GlobalConstants.data.personalized_config.has_add_card_text"
        >
          <div class="fnt-13" [ngStyle]="{ color: 'var(--primary-btn)' }">
            {{ GlobalConstants.data.content.add_card_text }}
          </div>
        </div>
      </div>
      <div class="flex last-b">
        <div class="ll">
          Your Card/ Account parameters are used only to check eligibility and
          are encrypted
        </div>
        <div class="rr">
          <img src="./assets/img/logos/PCI.svg" />
        </div>
      </div>
    </form>
  </section>
</div>
